$COLOR_PRIMARY: #3d61fb;
$COLOR_SUCCESS: #38a351;
$COLOR_WARNING: #f3ab3d;
$COLOR_DANGER: #d3463a;
$COLOR_PREMIUM: #f3ab3d;

$COLOR_GAP: #f3f4f5;

$COLOR_CAPTION: #626364;
$COLOR_CAPTION_DARK: #323334;
$COLOR_CAPTION_GRAY: #929394;
$COLOR_CAPTION_LIGHT: #c2c4c4;
$COLOR_CAPTION_LIGHTER: #e2e3e4;
$COLOR_CAPTION_LIGHTEST: #f2f3f4;

$COLOR_BACKGROUND_DARK: #333435;
$COLOR_BACKGROUND_GRAY: #e2e3e4;
$COLOR_BACKGROUND_LIGHT: #e7e8e9;
$COLOR_BACKGROUND_LIGHTER: #f2f3f4;
$COLOR_BACKGROUND_LIGHTEST: #f8f9fa;

$COLOR_DARK: #22272c;
$COLOR_GRAY: #f4f5f6;

$CONTAINER_WIDTH: 1200px;
$NAVBAR_WIDTH: 90px;
$TOPBAR_HEIGHT: 60px;
$FOOTER_HEIGHT: 30px;

@mixin primary_gradient {
  background-image: linear-gradient(darken($COLOR_PRIMARY, 10), darken($COLOR_PRIMARY, 25));
}

@mixin success_gradient {
  background-image: linear-gradient(darken($COLOR_SUCCESS, 0), darken($COLOR_SUCCESS, 10));
}

@mixin dark_gradient {
  background-image: linear-gradient(lighten($COLOR_DARK, 10), lighten($COLOR_DARK, 5));
}

@mixin darker_gradient {
  background-image: linear-gradient(lighten($COLOR_DARK, 5), lighten($COLOR_DARK, 0));
}

@mixin danger_gradient {
  background-image: linear-gradient(lighten($COLOR_DANGER, 5), lighten($COLOR_DANGER, 0));
}

@mixin primary_shadow {
  box-shadow: 4px 7px 10px 0px rgba($COLOR_PRIMARY, 0.3);
}

@mixin linear_spinning {
  animation: spinning 0.5s linear infinite;
}
