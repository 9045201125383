@import 'config';

@mixin for_book {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin for_pad {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin for_phone {
  @media screen and (max-width: 720px) {
    @content;
  }
}

@mixin softy {
  transition: background-color 0.4s, border 0.4s, box-shadow 0.4s, color 0.4s, transform 0.4s, opacity 0.4s;
}

@mixin rainbow_text($start, $stop) {
  background-image: linear-gradient(to bottom right, $start, $stop);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@mixin center_x {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin center_y {
  display: flex;
  flex-direction: column;
  align-items: center;
}
