@import "~assets/scss/_inc.scss";$publicAssetPath: 'https://static.jianli.online/site/public';
.component-switch {
  position: relative;
  background-color: currentColor;
  cursor: pointer;
  color: $COLOR_BACKGROUND_GRAY;
  @include softy;
  &:hover {
    color: darken($COLOR_BACKGROUND_GRAY, 5);
  }
  &.theme-primary.checked {
    color: $COLOR_PRIMARY;
  }
  &.theme-warning.checked {
    color: $COLOR_WARNING;
  }
  &.theme-danger.checked {
    color: $COLOR_DANGER;
  }
  &.theme-success.checked {
    color: $COLOR_SUCCESS;
  }
  &.small {
    width: 24px;
    height: 12px;
    border-radius: 6px;
  }
  &.normal {
    width: 28px;
    height: 14px;
    border-radius: 7px;
  }
  &.middle {
    width: 32px;
    height: 16px;
    border-radius: 8px;
  }
  &.large {
    width: 40px;
    height: 20px;
    border-radius: 10px;
  }
  &.huge {
    width: 48px;
    height: 24px;
    border-radius: 12px;
  }
  &.ultra {
    width: 64px;
    height: 32px;
    border-radius: 16px;
  }
  &.checked {
    &::before {
      transform: translateX(100%);
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &::before {
    box-sizing: border-box;
    display: block;
    width: 50%;
    height: 100%;
    background-color: #fff;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 50%;
    content: '';
    @include softy;
  }
}
