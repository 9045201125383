@import "~assets/scss/_inc.scss";$publicAssetPath: 'https://static.jianli.online/site/public';
.react-modal {
  @apply fixed z-9999 top-0 left-0 w-full h-full;
}

.react-modal-mask {
  @apply absolute z-1  top-0 left-0 w-full h-full bg-black/20 opacity-0 transition duration-300;
}

.react-modal-content-wrapper {
  @apply relative z-2 flex flex-col items-center justify-center h-full;
}

.react-modal-content {
  @apply relative flex flex-col justify-between rounded-lg opacity-0 transition-all duration-300;
  max-width: 90vw;
  max-height: 80vh;
  transform: scale(.98);
  will-change: transform, opacity;
}

.react-modal-header {
  @apply flex flex-shrink-0 items-start justify-between leading-5;
}

.react-modal-caption {
  @apply text-gray-500 text-base font-medium leading-5 text-left;
}

.react-modal-close-button {
  @apply absolute top-3 right-3 flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full text-gray-400 cursor-pointer transition-colors hover:bg-gray-200;
}

.react-modal-body {
  @apply flex-1 overflow-auto;
}

.react-modal-footer {
  @apply flex-shrink-0 overflow-hidden;
}

.react-modal-addon-text {
  @apply float-left text-gray-300 text-xs leading-6;
}

.react-modal-buttons {
  @apply float-right flex;
  button {
    @apply flex-1 min-w-[140px] mx-2 px-4 text-sm;
  }
}

.react-modal.active {
  .react-modal-mask {
    opacity: 1;
  }

  .react-modal-content {
    opacity: 1;
    transform: translateY(-40px);
  }
}
