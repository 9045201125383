@import "~assets/scss/_inc.scss";$publicAssetPath: 'https://static.jianli.online/site/public';
.component-select {
  appearance: none;
  &.large,
  &.large .react-select__control{
    @apply h-14;
  }
  &.medium,
  &.medium .react-select__control{
    @apply h-12;
  }
  &.small,
  &.small .react-select__control{
    @apply h-10;
  }
  &.tiny,
  &.tiny .react-select__control{
    @apply h-8 text-tiny;
  }
  &:focus-within {
    @apply transition-colors bg-gray-100;
  }
  .react-select {
    &__control {
      min-height: 10px;
      background-color: transparent;
      border: none;
      &--menu-is-open {
        box-shadow: 0 0 0 2px $COLOR_PRIMARY;
      }
    }
    &__single-value {
      @apply text-gray-500;
    }
    &__input-container {
      @apply text-gray-500;
    }
    &__indicator-separator {
      @apply bg-gray-200;
    }
    &__indicator {
      @apply p-1 cursor-pointer text-gray-300 hover:text-gray-400;
    }
    &__menu {
      @apply bg-gray-50;
    }
    &__option {
      @apply cursor-pointer bg-white/0 hover:bg-gray-100;
      &--is-focused {
        @apply bg-gray-200;
      }
      &--is-selected {
        @apply bg-primary hover:bg-primary;
      }
    }
  }
}
