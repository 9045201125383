@import "~assets/scss/_inc.scss";$publicAssetPath: 'https://static.jianli.online/site/public';
@import 'inc';
@import 'modals';
@import 'animations';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'PingFang SC', -apple-system, 'Microsoft Yahei', 微软雅黑, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    caption, arial, sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input,
textarea {
  outline: none;
}

body {
  overflow: auto;
  @include for_book {
    overflow-x: hidden;
  }
}

body.noscroll {
  overflow: hidden;
}

.markdown-body {
  ul li {
    &:only-child {
      margin: 0 !important;
      padding: 0;
    }
  }
  ul li {
    list-style-type: disc;
  }
}

.page-gray {
  background-color: $COLOR_BACKGROUND_LIGHTER;
}

* {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    padding-right: 2px;
    background-color: #e2e3e4;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c2c3c4;
    &:hover {
      background-color: #a2a4a5;
    }
  }
}

.button {
  &-primary {
    @apply bg-primary bg-gradient-to-b from-white/10 to-white/0 text-white transition-colors hover:bg-primary-dark;
  }
  &-normal {
    @apply bg-gray-100 text-gray-500 transition-colors hover:bg-gray-200/60;
  }
  &-white {
    @apply bg-white text-gray-500 transition-colors hover:bg-gray-200;
  }
  &-premium {
    @apply bg-amber-500 bg-gradient-to-b from-white/10 to-white/0 text-white transition-colors hover:bg-amber-600;
  }
  &-free {
    @apply bg-green-500 bg-gradient-to-b from-white/10 to-white/0 text-white transition-colors hover:bg-green-600;
  }
  &-gray {
    @apply bg-gray-200 text-gray-500 transition-colors hover:bg-gray-300/60;
  }
  &-warning {
    @apply bg-amber-600/10 text-amber-500 transition-colors hover:bg-amber-300/15;
  }
  &-warning-solid {
    @apply bg-amber-500 bg-gradient-to-b from-white/10 to-white/0 text-white transition-colors hover:bg-amber-400;
  }
  &-danger {
    @apply bg-red-600/10 text-red-600 transition-colors hover:bg-red-600/15;
  }
}

.softy {
  @include softy;
}

.with-artboard-bg {
  background-color: #fff;
  background-image: url($publicAssetPath + '/images/artboard_grid.png');
  background-size: 12px 12px;
}

.with-water-marker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-image: url($publicAssetPath + '/images/resume_water_marker.png');
  background-size: 200px 200px;
  pointer-events: none;
}

.rich-text-content {
  a {
    color: $COLOR_PRIMARY;
    &:hover {
      text-decoration: underline;
    }
  }
}

.print-only {
  display: none !important;
}

@media print {
  .print-hidden {
    display: none !important;
  }
  .print-only {
    display: unset !important;
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes background_translate {
  0% {
    background-position-x: 0%;
  }
  50% {
    background-position-x: 320px;
  }
  100% {
    background-position-x: 640px;
  }
}
